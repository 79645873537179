.service-card {
    background-color: #f5f6f7;
    margin: 10px;
    padding: 20px;
    border-radius: 20px;
    height: 200px;
    position: relative;
    transition: var(--transition);
    z-index: 1;
}

.service-card:hover {
    transform: translateY(-10px);
    cursor: pointer;
}

.service-card:hover > img {
    transform: translateY(-10px);
    border-radius: 35px;
    border: 1px solid green;
    padding: -10px;
    margin-right: 10px;
}


.service-card p {
    max-width: 50%;
}

.service-card img {
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 100px;
    height: 100px;
    border-radius: 100%;
    padding: 20px;
}

.nopadding {
    padding: 0 !important;
    margin: 0 !important;
}

.bg-primary h4{
    color: white;
}

.bg-secondary h4{
    color: white;
}