.banner-gradient {
    position: relative;
    /* Définissez la position relative pour le positionnement de l'élément ::before */
    background-image: linear-gradient(to top, rgba(213, 63, 13, 0.5), #0E2667), url(../../../public/assets/img/company-banner.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;
}


.company-banner {
    background-image: linear-gradient(to top, rgba(213, 63, 13, 0.5), #0E2667), url(../../../public/assets/img/company-banner.webp);
}

.client-banner {
    /* Définissez la position relative pour le positionnement de l'élément ::before */
    background-image: linear-gradient(to top, rgba(213, 63, 13, 0.5), rgba(0, 0, 255, 0.5)), url(../../../public/assets/img/banner3.jpg);
}

.mt-200{
    margin-top: 200px;
}

.float-img img{
    display: flex;
    position: absolute;
    right: 10px;
    top: -100px;
}

